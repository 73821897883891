import {
  UseMutationResponse,
  UseQueryResponse,
} from '@urql/vue';
import { AuthResponse, MeResponse, LoginResponse, TokenLoginResponse } from '@graphql/types/auth';
import { useAuthStore } from '@stores/useAuthStore';
import { useOnboardingStore } from '@stores/useOnboardingStore';

export const useAuthenticated = async (query: UseQueryResponse<MeResponse, Record<string, unknown>>)
  : Promise<boolean> => {
  const auth = useAuthStore();

  if (!auth.user) {
    const { data } = await query;
    auth.user = data.value?.me || null;
  }

  return auth.user !== undefined;
};

export const useAuth = () => {
  const login = async (username: string, password: string,
    mutation: UseMutationResponse<LoginResponse, Record<string, unknown>>): Promise<AuthResponse> => {
    const { data, error } = await mutation.executeMutation({ input: { username, password } });

    const auth = useAuthStore();
    const onboarding = useOnboardingStore();

    onboarding.setIsOnboardingSkipped(false);

    if (data) {
      auth.login(data.login.user, data.login.access_token, data.login.refresh_token, Number(data.login.expires_in));
      auth.addOrUpdateAccount({
        id: data.login.user.id,
        name: data.login.user.name,
        email: username,
        avatar_url: data.login.user.avatar_url,
        last_login: Date.now(),
      });

      return { success: true };
    }

    if (error && error.message.includes('lijst met onveilige wachtwoorden')) {
      return {
        success: false,
        errorMessage: `U gebruikt een wachtwoord die voor komt in een lijst met onveilige wachtwoorden.
        Kies alstublieft een ander wachtwoord via de wachtwoord vergeten pagina.`,
      };
    }

    return { success: false };
  };

  const impersonate = async (token: string, mutation: UseMutationResponse<TokenLoginResponse,
    Record<string, unknown>>): Promise<boolean> => {
    const { data } = await mutation.executeMutation({ input: { token } });

    const auth = useAuthStore();

    if (data) {
      auth.activeFunderId = 0;

      auth.login(data.tokenLogin.user, data.tokenLogin.access_token, data.tokenLogin.refresh_token,
        Number(data.tokenLogin.expires_in));

      return true;
    }

    auth.logout();

    return false;
  };

  const logout = async (mutation: UseMutationResponse<undefined, Record<string, unknown>>) => {
    const auth = useAuthStore();

    auth.logout();

    await mutation.executeMutation({});
    window.location.reload();
  };

  return {
    login,
    impersonate,
    logout,
  };
};
